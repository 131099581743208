import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

export class InvalidTokenError extends Error {
}
InvalidTokenError.prototype.name = "InvalidTokenError";
function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).replace(/(.)/g, (m, p) => {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
            code = "0" + code;
        }
        return "%" + code;
    }));
}
function base64UrlDecode(str) {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += "==";
            break;
        case 3:
            output += "=";
            break;
        default:
            throw new Error("base64 string is not of the correct length");
    }
    try {
        return b64DecodeUnicode(output);
    }
    catch (err) {
        return atob(output);
    }
}
export function jwtDecode(token, options?) {
    if (typeof token !== "string") {
        throw new InvalidTokenError("Invalid token specified: must be a string");
    }
    options || (options = {});
    const pos = options.header === true ? 0 : 1;
    const part = token.split(".")[pos];
    if (typeof part !== "string") {
        throw new InvalidTokenError(`Invalid token specified: missing part #${pos + 1}`);
    }
    let decoded;
    try {
        decoded = base64UrlDecode(part);
    }
    catch (e) {
        throw new InvalidTokenError(`Invalid token specified: invalid base64 for part #${pos + 1} (${e.message})`);
    }
    try {
        return JSON.parse(decoded);
    }
    catch (e) {
        throw new InvalidTokenError(`Invalid token specified: invalid json for part #${pos + 1} (${e.message})`);
    }
}

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

function Tabs(props: TabsProps) {
  const activeIndex = props.selectedIndex ?? 0;
  if (!props.children) {
    return <></>;
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        style={{ backgroundColor: '#4d0c7ee6' }}
        onClick={() => {
          if (props.onTabSelected) props.onTabSelected(index);
        }}
        
        aria-pressed={activeIndex === index}
      >
        {child?.props.label}
      </button>
    );
  });
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {props.children[activeIndex]}
    </div>
  );
}

function DemoMeetingTab({ label }: { label: string }) {
  const router = useRouter();
  
  const [serverUrl, setserverUrl] = useState(process.env.NEXT_PUBLIC_LIVEKIT_URL); 
  const [serverFQDN, setserverFQDN] = useState(process.env.NEXT_PUBLIC_LIVEKIT_API_FQDN); 
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [roomName, setRoomName] = useState(generateRoomId());
  const [username, setUsername] = useState("");
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  
  
  const startMeeting = () => {
    setserverUrl(process.env.NEXT_PUBLIC_LIVEKIT_URL);
    setserverFQDN(process.env.NEXT_PUBLIC_LIVEKIT_API_FQDN);
    var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance 
    xmlhttp.open("POST", `https://${serverFQDN}/silverkit/api/token/create`);
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlhttp.responseType = 'json';
    xmlhttp.onload = function(e) {
      if (this.status == 400) {
        alert(this.response.detail)
      } else if (this.status == 200) {
        setToken(this.response.token)
        if (e2ee) {
          
          router.push(
            `/custom/?liveKitUrl=${serverUrl}&token=${this.response.token}#${encodePassphrase(sharedPassphrase)}`,
          );
        } else {
          router.push(`/custom/?liveKitUrl=${serverUrl}&token=${this.response.token}`);
        }
      }
    };
    xmlhttp.send(JSON.stringify({
      "username": `${username}`,
      "password": `${password}`,
      "room": `${roomName}`,
      "ttl": 24,
    }));
  };
  return (
    <div className={styles.tabContent}
    style={{ backgroundColor: '#2804447d' }}>
      <p style={{ margin: 0 }}></p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <p style={{ marginTop: 0 }}>
            Usuario
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: -30 }}>
          <input
          id="u_username"
          name="u_username"
          type="text"
          placeholder="Nombre de usuario"    
          required
          style={{width: "100%", padding: '8px'}}

          onChange={(ev) => setUsername(ev.target.value)}
          />  
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: -10 }}>
            <p style={{ marginTop: -10 }}>
            Contraseña
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: -30 }}>
          <input
          id="u_password"
          name="u_password"
          type="text"
          placeholder="Password"    
          required
          style={{width: "100%", padding: '8px'}}
          onChange={(ev) => setPassword(ev.target.value)}
          />  
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: -10 }}>
            <p style={{ marginTop: -10 }}>
            Sala
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: -30 }}>
          <input
          id="u_roomname"
          name="u_roomname"
          type="text"
          placeholder="Sala"    
          required
          style={{width: "100%", padding: '8px'}}

          onChange={(ev) => setRoomName(ev.target.value)}
          />  
        </div>

      </div>
      <button style={{ marginTop: '1rem', backgroundColor: '#4d0c7ee6' }} className="lk-button" onClick={startMeeting}>
        Iniciar conferencia
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Habilitar cifrado punto a punto.</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Contraseña de E2E</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function CustomConnectionTab({ label }: { label: string }) {
  const router = useRouter();
  const serverUrl = process.env.NEXT_PUBLIC_LIVEKIT_URL;
  const serverFQDN = process.env.NEXT_PUBLIC_LIVEKIT_API_FQDN;
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [connectionData, setConnectionData] = useState("Datos de conexión");

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const serverUrl = formData.get('serverUrl');
    const token = formData.get('token');
    if (e2ee) {
      router.push(
        `/custom/?liveKitUrl=${serverUrl}&token=${token}#${encodePassphrase(sharedPassphrase)}`,
      );
    } else {
      router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
    }
  };
  return (
    <form 
    className={styles.tabContent} 
    onSubmit={onSubmit}
    style={{ backgroundColor: '#2804447d' }}
    >
      <p style={{ marginTop: 0 }}>
        Introduzca el token que ha recibido.
      </p>
      
      <textarea
        id="token"
        name="token"
        placeholder="Token"
        required
        rows={5}
        style={{ padding: '8px', fontSize: 'inherit', lineHeight: 'inherit'}}
        onChange={(ev) => setConnectionData(JSON.stringify(jwtDecode(ev.target.value), null, 2))}
      />
      <textarea
        id="datos"
        name="datos"
        placeholder="Datos de conexión"
        rows={5}
        value={connectionData}
        readOnly
        disabled
        style={{ padding: '8px', fontSize: 'inherit', lineHeight: 'inherit' }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Habilitar cifrado punto a punto.</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Contraseña de E2E</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>

      <hr
        style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}
      />
      <button
        style={{ paddingInline: '1.25rem', width: '100%', backgroundColor: '#4d0c7ee6' }}
        className="lk-button"
        type="submit"
      >
        Conectar
      </button>
    </form>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
      <video autoPlay muted loop id="back-video">
        <source src="/images/back_silver.mp4" type="video/mp4"/>
      </video>
        <div className="header">
          <img src="/images/logo_completo_blanco.svg" alt="Silverline Meet" width="auto" height="150" />
          <h2>
            Introduzca su usuario, contraseña y nombre de la sala. Si tiene un token de acceso, escoja la opción correspondiente.
          </h2>
        </div>
        <Tabs selectedIndex={tabIndex} onTabSelected={onTabSelected}>
          <DemoMeetingTab label="Credenciales" />
          <CustomConnectionTab label="Token" />
        </Tabs>
      </main>
      <footer data-lk-theme="default">
        <a href="https://ravenloop.io/" rel="noopener">
        © 2024 Ravenloop
        </a>
        
      </footer>
    </>
  );
};

export default Home;
